import React, { FC, useState, useEffect } from 'react'
import { body, formatBody, title } from './localization'
import { InsightsCard, InsightsCardProps } from '../../components/InsightsCard'
import { Text } from 'components/Text'
import { DailyPuffTreemap } from '../../charts/DailyPuffTreemap'
import { WebAppInsights, Event } from 'analytics-events'

// config
const MAX_TREEMAP_HEIGHT = 360

// types
export type AveragePuffDayProps = Omit<InsightsCardProps, 'title'>

export const AveragePuffDay: FC<AveragePuffDayProps> = ({
  loading,
  ...rest
}) => {
  const [isValid, setIsValid] = useState<boolean>(null)
  const [averagePuffDayEvent, setAveragePuffDayEvent] = useState<Event>(null)
  const [minPuffDay, setMinPuffDay] = useState<string>(null)
  const [maxPuffDay, setMaxPuffDay] = useState<string>(null)

  useEffect(() => {
    if (isValid === null) return

    setAveragePuffDayEvent(WebAppInsights.AveragePuffDaysEvent({ isValid }))
  }, [isValid])

  return (
    <InsightsCard
      cardEvent={averagePuffDayEvent}
      isValid={isValid}
      title={title}
      loading={loading || isValid === null}
      {...rest}
    >
      {minPuffDay && maxPuffDay && (
        <Text
          message={body}
          tagName='p'
          typography='bodyBig'
          values={formatBody(minPuffDay, maxPuffDay)}
        />
      )}
      <DailyPuffTreemap
        margin={{ top: 'baseLoose' }}
        maxHeight={MAX_TREEMAP_HEIGHT}
        setIsValid={setIsValid}
        setMaxPuffDay={setMaxPuffDay}
        setMinPuffDay={setMinPuffDay}
      />
    </InsightsCard>
  )
}
