import React, { FC, useState, useEffect } from 'react'
import { DailyPuffTrendline } from '../../charts/DailyPuffTrendline'
import { InsightsCard, InsightsCardProps } from '../../components/InsightsCard'
import { Text } from 'components/Text'
import { description, title } from './localization'
import { WebAppInsights, Event } from 'analytics-events'

// config
const TRENDLINE_CHART_HEIGHT = 240

// types
export type PuffsSoFarProps = Omit<InsightsCardProps, 'title'>

export const PuffsSoFar: FC<PuffsSoFarProps> = ({ loading, ...rest }) => {
  const [isValid, setIsValid] = useState<boolean>(null)
  const [puffSoFarEvent, setPuffSoFarEvent] = useState<Event>(null)
  const [puffCount, setPuffCount] = useState(0)

  useEffect(() => {
    if (isValid === null) return

    setPuffSoFarEvent(WebAppInsights.PuffsSoFarEvent({ isValid }))
  }, [isValid])

  return (
    <InsightsCard
      cardEvent={puffSoFarEvent}
      isValid={isValid}
      title={title}
      loading={loading || isValid === null}
      {...rest}
    >
      <Text
        margin={{ bottom: 'baseLoose' }}
        message={description}
        tagName='p'
        typography='bodyBig'
        values={{ puffs: puffCount }}
      />
      <DailyPuffTrendline
        height={TRENDLINE_CHART_HEIGHT}
        setIsValid={setIsValid}
        setPuffCount={setPuffCount}
      />
    </InsightsCard>
  )
}
