import React, { FC, useState } from 'react'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Box } from 'components/Box'
import { InsightsCard, InsightsCardProps } from '../../components'
import { SlidingTabs } from 'components/SlidingTabs'
import {
  PodHistoryDonut,
  PodHistoryPeriod,
} from '../../charts/PodHistoryDonut'

// config
const POD_HISTORY_CHART_MAX_DIAMETER = 360

// data
export const podHistoryTitle = 'Pod History'
const AVAILABLE_PERIODS: PodHistoryPeriod[] = [
  PodHistoryPeriod.SevenDays,
  PodHistoryPeriod.ThirtyDays,
  PodHistoryPeriod.NinetyDays,
]

// types
export type PodHistoryProps = Omit<InsightsCardProps, 'title'>

export const PodHistory: FC<PodHistoryProps> = ({ ...rest }) => {
  const { trackClickEvent } = useAnalytics()

  const [
    timePeriod,
    setTimePeriod,
  ] = useState<PodHistoryPeriod>(PodHistoryPeriod.SevenDays)

  const trackTimePeriod = (value) => {
    // This is sensitive to the PodHistoryPeriod type and will need to be changed with it.
    const trackedValue = value.split(' ')[0] + 'Days'
    trackClickEvent({
      screenEvent: 'UsageMonitor',
      viewClicked: `PodHistoryChart|${trackedValue}`,
    })
    setTimePeriod(value)
  }

  const podHistoryControls = (
    <Box padding={{ top: 'tight' }}>
      <SlidingTabs
        id='podHistory'
        onClickValue={trackTimePeriod}
        values={AVAILABLE_PERIODS}
      />
    </Box>
  )

  return (
    <InsightsCard
      controls={podHistoryControls}
      title={podHistoryTitle}
      {...rest}
    >
      <PodHistoryDonut
        padding={{ top: 'baseLoose' }}
        maxDiameter={POD_HISTORY_CHART_MAX_DIAMETER}
        timePeriod={timePeriod}
      />
    </InsightsCard>
  )
}
