import React, {
  FC,
  useEffect,
  PropsWithChildren,
  ComponentPropsWithoutRef,
} from 'react'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Event, WebAppScreens } from 'analytics-events'
import { classNames } from 'utils/css'

export interface PageProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'div'>> {
    className?: string
    screen?: {
      event: Event
      name: WebAppScreens.Name
    }
  }

// todo incorporate frequently used Page styles and remove grid
export const Page: FC<PageProps> = ({
  children,
  className,
  screen,
  ...rest
}) => {
  const { reportEvent } = useAnalytics()

  useEffect(() => {
    if (!screen?.event) return

    reportEvent(screen.event)
  }, [screen?.name, screen?.event])

  return (
    <div className={classNames('Page', className)} {...rest}>
      {children}
    </div>
  )
}
