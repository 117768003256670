/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** Usage Insight and Activity Update WebApp States */
export namespace WebAppInsights {

  type AveragePuffDaysEventProps = {
    isValid: boolean
  }

  export const AveragePuffDaysEvent = ({
    isValid,
  }: AveragePuffDaysEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'AveragePuffDays',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type AveragePuffHoursEventProps = {
    isValid: boolean
  }

  export const AveragePuffHoursEvent = ({
    isValid,
  }: AveragePuffHoursEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'AveragePuffHours',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type LongTermTrendExpandedEventProps = {
    isValid: boolean
  }

  export const LongTermTrendExpandedEvent = ({
    isValid,
  }: LongTermTrendExpandedEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'LongTermTrendExpanded',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type LongTermTrendPreviewEventProps = {
    isValid: boolean
  }

  export const LongTermTrendPreviewEvent = ({
    isValid,
  }: LongTermTrendPreviewEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'LongTermTrendPreview',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PuffHistoryEventProps = {
    isValid: boolean
  }

  export const PuffHistoryEvent = ({
    isValid,
  }: PuffHistoryEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PuffHistory',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PuffsSoFarEventProps = {
    isValid: boolean
  }

  export const PuffsSoFarEvent = ({
    isValid,
  }: PuffsSoFarEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PuffsSoFar',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PuffsThisWeekEventProps = {
    isValid: boolean
  }

  export const PuffsThisWeekEvent = ({
    isValid,
  }: PuffsThisWeekEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PuffsThisWeek',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type ShortTermTrendExpandedEventProps = {
    isValid: boolean
  }

  export const ShortTermTrendExpandedEvent = ({
    isValid,
  }: ShortTermTrendExpandedEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'ShortTermTrendExpanded',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type ShortTermTrendPreviewEventProps = {
    isValid: boolean
  }

  export const ShortTermTrendPreviewEvent = ({
    isValid,
  }: ShortTermTrendPreviewEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'ShortTermTrendPreview',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }
}
